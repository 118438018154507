<template>
  <div>
    <a-table
      :columns="governReward.columns"
      :dataSource="governReward.dataSource"
      :loading="ui.loading"
      rowKey="id"
      :pagination="{ pageSize: 10 }"
    ></a-table>
  </div>
</template>

<script>
import { getGovernmentRewardList } from "@/api/company";

const governRewardColumns = [
  {
    title: "奖励名称",
    dataIndex: "name",
  },
  {
    title: "奖励类型",
    dataIndex: "type",
  },
  {
    title: "奖励项目",
    dataIndex: "product",
  },
  {
    title: "新闻年份",
    dataIndex: "year",
  },
  {
    title: "奖励来源",
    dataIndex: "origin",
  },
];

export default {
  name: "govern-reward-manage",
  data() {
    return {
      governReward: {
        columns: governRewardColumns,
        dataSource: [],
        info: {},
      },
      ui: {},
    };
  },
  created() {
    this.companyId = this.$route.params.companyId;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.ui.loading = true;
      getGovernmentRewardList({
        companyId: this.companyId,
      }).then((res) => {
        this.governReward.dataSource = res.data;
        this.ui.loading = false;
      });
    },
  },
};
</script>